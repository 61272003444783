<template>
  <AppLoading v-if="loading" />

  <main class="AccountingDetailsView" v-if="!loading">
    <div v-for="(invoice, index) in invoices" :key="invoice.id">
      <div class="AccountingDetailsView__separator">
        {{ invoice.client.number }} — {{ invoice.client.lastname }} {{ invoice.client.firstname }}
      </div>

      <InvoicePage1 :invoice="invoice" :page="(index + 1) + ' — 1 / 2'" />

      <InvoicePage2 :invoice="invoice" :page="(index + 1) + ' — 2 / 2'" />
    </div>
  </main>
</template>

<script>
  import AppLoading from '@/components/AppLoading.vue'
  import InvoicePage1 from '@/components/InvoicePage1.vue'
  import InvoicePage2 from '@/components/InvoicePage2.vue'

  import invoiceService from '@/services/invoices.service'
  import settingService from '@/services/settings.service'

  import { formatRelativeDate, formatDate } from '@/utils/date'
  import { formatDecimal } from '@/utils/number'
  import { orderBy } from 'lodash'

  export default {
    components: {
      AppLoading,
      InvoicePage1,
      InvoicePage2
    },

    data() {
      return {
        loading: true,
        invoices: null
      }
    },

    mounted() {
      this.load()
    },

    methods: {
      formatDate,
      formatRelativeDate,
      formatDecimal,

      async load() {
        this.loading = true

        const report = await invoiceService.fetchReport()
        const settings = await settingService.fetch()

        try {
          const year = 'Y' + this.$route.params.year
          const month = 'M' + this.$route.params.month

          let invoices = report.year[year].month[month].invoices

          invoices = orderBy(invoices, 'number', 'asc')

          this.invoices = invoices.map((invoice) => {
            invoice.global_settings = settings

            return invoice
          })

        } catch (e) {
          this.$router.replace({ name: 'error-404' })
        }

        this.loading = false
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import '@/sass/styles.vars.all.sass'

  .AccountingDetailsView
    -webkit-print-color-adjust: exact
    font-size: .9em

  .AccountingDetailsView__separator
    text-align: center
    margin-top: $spacer
    margin-bottom: -$spacer-4
    padding: $spacer
    display: block
    color: $gray-500
    border-top: 1px dotted $gray-500
    border-bottom: 1px dotted $gray-500

  @media print
    .AccountingDetailsView__separator
      display: none
</style>
